import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Button from "./button"
import { colors, Desktop, Mobile, Filler } from "../theme"
import { TranslationContext } from "../translate"


const Image = styled.div`
    background: transparent url(${({src}) => src}) no-repeat center center;
    background-size: cover;
`

const CardWrapper = styled.div`
    flex: 1;
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: ${({box}) => box ? "#fff" : "transparent"};
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
      flex-direction: ${({imagePosition}) => imagePosition === "left" ? "row" : (imagePosition === "right" ? "row-reverse" : "column")};
    }
`

const CardImage = styled(Image)`
    width: 100%;
    height: 0;
    padding-top: 66.66%;
    padding-left: 2em;
    padding-right: 2em;
    box-sizing: border-box;
    margin-bottom: ${({imagePosition}) => (imagePosition === "left" || imagePosition === "right") ? "0" : "2em"};
    display: flex;
    flex: ${({imagePosition}) => (imagePosition === "left" || imagePosition === "right") ? "1" : "0"};
    @media (min-width: 769px) {
      padding-top: ${({imagePosition}) => (imagePosition === "left" || imagePosition === "right") ? "0" : "66.66%"};
      height: ${({imagePosition}) => (imagePosition === "left" || imagePosition === "right") ? "auto" : "0"};
      width: ${({imagePosition}) => (imagePosition === "left" || imagePosition === "right") ? "auto" : "100%"};
    }
`

const CardTitle = styled.h3`
    font-weight: 400 !important;
    font-size: 1.8em !important;
    line-height: 1.25em;
    margin: 0 0 0.75em 0 !important;
    border: none !important;
`

const CardContent = styled.div`
    text-align: left;
    flex: 1;
    display: flex;
    line-height: 1.5em;
    align-items: flex-start;
    flex-direction: column;
    padding: ${({box}) => box ? "2em" : "0"};
    p {
      margin-top: 0;
      margin-bottom: 1.5em;
    }
`

const BannerWrapper = styled(Image)`
    padding: 2em;
    margin: 0;
    box-sizing: border-box;
    @media (min-width: 769px) {
        margin: 2em;
    }
`

const BannerBox = styled(Image)`
    background: #fff;
    max-width: 55em;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
        flex-direction: row;
    }
`

const BannerColumn = styled.div`
    padding: 2em;
    p {
        margin: 0 0 1em 0;
        line-height: 1.5em;
        &:first-child {
            color: ${colors.kaisla};
        }
    }
`

const BannerTitleColumn = styled(BannerColumn)`
    padding-bottom: 0em;
    display: flex;
    flex-direction: column;
    @media (min-width: 769px) {
        padding-bottom: 2em;
        border-right: 1px solid #e0e0e0;
    }
`

const BannerContentColumn = styled(BannerColumn)`
    font-style: italic;
    padding-top: 1.5em;
    @media (min-width: 769px) {
        padding-top: 2em;
    }
`

const BannerTitle = styled.h2`
    margin: 0 !important;
    text-transform: uppercase;
    text-align: left !important;
    font-size: 2.5em;
    line-height: 0.9em;
    font-weight: 900 !important;
    span:first-child {
        font-weight: 200;
    }
    @media (min-width: 769px) {
        margin-bottom: 0.4em;
        font-size: 3.5em;
    }
`


export default function({title, content, image, button, banner, bannerMargin, box, imagePosition}) {
  if(banner) {
      const bannerBoxStyle = {}
      if (bannerMargin === "Top") {
        bannerBoxStyle.marginTop = "30em"
      }
      if (bannerMargin === "Bottom") {
        bannerBoxStyle.marginBottom = "30em"
      }
      return <BannerWrapper src={image.localFile.childImageSharp.fixed.src}>
        <TranslationContext.Consumer>{(t) =>
          <BannerBox style={bannerBoxStyle}>
            <BannerTitleColumn>
              <BannerTitle>{t(title).split(" ").map((word, i) => <span key={i}>{word} </span>)}</BannerTitle>
              <Filler style={{minHeight: "1em"}} />
              {button && <Desktop><Button primary color={colors.kaisla} {...button} /></Desktop>}
            </BannerTitleColumn>
            <BannerContentColumn>
                {renderRichText(t(content, true))}
              {button && <Mobile><Button color={colors.kaisla} {...button} /></Mobile>}
            </BannerContentColumn>
          </BannerBox>
        }</TranslationContext.Consumer>
      </BannerWrapper>
  }
  return <CardWrapper box={box} imagePosition={imagePosition}>
    {image && <CardImage src={image.localFile.childImageSharp.fixed.src} imagePosition={imagePosition} />}
    <CardContent box={box}>
      <TranslationContext.Consumer>{(t) => <>
        {title && <CardTitle>{t(title)}</CardTitle>}
        {content && renderRichText(t(content, true))}
        {button && <Button {...button} />}
      </>}</TranslationContext.Consumer>
    </CardContent>
  </CardWrapper>
}

export const query = graphql`
  fragment Card on ContentfulCard {
    title
    content {
      raw
    }
    banner
    image {
      localFile {
        childImageSharp {
          fixed(width: 1500) {
            src
          }
        }
      }
    }
    button {
      ...Button
    }
    bannerMargin
    box
    imagePosition
  }
`